<template>
    <div class="paymentResult-container">
        <div class="main" v-if="result">
            <img alt="" src="@/assets/images/success@2x.png" style="width:100px ">
            <h1 class="res-text">{{ result }}</h1>
            <p class="time">{{ $t("myWallet.itWillJumpToMyWalletInSeconds") }}</p>
            <CustomButton @click.native="$router.push('/wallet')" active>{{ $t("myWallet.backToWallet") }}
            </CustomButton>
        </div>
    </div>
</template>

<script>
    import {payCompleteInfo} from '@/api/payment'
    import getUrlQuery from "@/utils/getUrlQuery";
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";

    export default {
        name: "paymentResult",
        data() {
            return {
                result: '',
            };
        },
        components: {CustomButton},
        props: {},
        created() {
        },
        mounted() {
            this.getPayCompleteInfo();
        },
        methods: {
            getPayCompleteInfo() {
                let formData = {
                    token: getUrlQuery.getUrlKey('token'),
                }
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                payCompleteInfo(formData).then((res) => {
                    loading.close();
                    if (res.code == 200) {
                        this.result = this.$t("myWallet.youHavePaidSuccessfully");//'支付成功'
                        this.$message.success(res.msg);
                    } else {
                        this.result = this.$t("myWallet.paymentFailed");
                        this.$message.error(res.msg);
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .paymentResult-container {
        position: relative;
        height: calc(100vh - 132px);
        width: 100%;
    }

    .main {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        text-align: center;
    }

    .main .res-text {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 29px;
        color: #000000;
        margin: 16px 0 22px;
    }

    .main .time {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #707070;
        margin-bottom: 42px;
    }
</style>
